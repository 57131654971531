import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function ProfileDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { guid, VehicleOwnerMasterId, strEmail, strPhone } = location.state || {};

  const [strName, setStrName] = useState('');
  const [strDOB, setStrDOB] = useState('');
  const [strAddress, setStrAddress] = useState('');
  const [strCity, setStrCity] = useState('');
  const [strState, setStrState] = useState('');
  const [strPincode, setStrPincode] = useState('');
  const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
  const [vehiclePlateNumber, setVehiclePlateNumber] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [plateNumberError, setPlateNumberError] = useState('');
  const [emergencyNumberError, setEmergencyNumberError] = useState('');
  // const [dobError, setDobError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (guid && VehicleOwnerMasterId) {
      localStorage.setItem('guid', guid);
      localStorage.setItem('VehicleOwnerMasterId', VehicleOwnerMasterId);
    }
    console.log('GUID:', guid);
    console.log('VehicleOwnerMasterId:', VehicleOwnerMasterId);
  }, [guid, VehicleOwnerMasterId]);

  const validatePlateNumber = (vehiclePlateNumber) => {
    const regex = /^[A-Za-z0-9]{4,10}$/;
    if (!regex.test(vehiclePlateNumber)) {
      setPlateNumberError('Vehicle plate number should be alphanumeric and between 4 and 10 characters long.');
      return false;
    }
    setPlateNumberError('');
    return true;
  };

  const validateEmergencyContactNumber = (emergencyContactNumber) => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(emergencyContactNumber)) {
      setEmergencyNumberError('Emergency contact number should be exactly 10 digits long and contain only numbers.');
      return false;
    }
    setEmergencyNumberError('');
    return true;
  };

  const validateForm = () => {
    let isValid = true;
    if (!validatePlateNumber(vehiclePlateNumber)) {
      isValid = false;
    }

    if (!validateEmergencyContactNumber(emergencyContactNumber)) {
      isValid = false;
    }

    // if (!strDOB) {
    //   setDobError('Date of Birth is required.');
    //   isValid = false;
    // } else {
    //   setDobError('');
    // }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const data = {
      strName,
      strPhone,
      strEmail,
      strDOB,
      strAddress,
      strCity,
      strState,
      strPincode,
      emergencyContactNumber,
      vehiclePlateNumber,
      bloodGroup,
      guid,
      VehicleOwnerMasterId
    };

    try {
      const response = await axios.post('https://apolloinfotech.in/QR_Genie/api/addvehicledetail', data);
      if (response.status === 200) {
        navigate('/thank-you-postpaid');
      } else {
        setError('There was an error submitting the form.');
      }
    } catch (error) {
      setError('There was an error submitting the form.');
      console.error('Submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="untree_co-section mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <div className='text-center'>
                <h2 className="h3 mb-3 text-black">Profile Details</h2>
                <img src="assests/image/profile_detail.png" alt="Image Description" className="img-fluid mb-4 text-center" />
              </div>

              <form id="billingForm" onSubmit={handleSubmit}>
                <div className="p-3 p-lg-5 border bg-white">
                  <div className="form-group row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="strName" className="text-black">Name <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="strName" name="strName" maxLength="50"
                        value={strName} onChange={(e) => setStrName(e.target.value)} required />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="strDOB" className="text-black">Date of Birth <span className="text-danger">*</span></label>
                      <input type="date" className="form-control" id="strDOB" name="strDOB"
                        value={strDOB} onChange={(e) => setStrDOB(e.target.value)} required />
                      {/* {dobError && <div className="text-danger">{dobError}</div>} */}
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="bloodGroup" className="text-black">Blood Group <span className="text-danger">*</span></label>
                      <select className="form-control blood_group_select" id="bloodGroup" name="bloodGroup" value={bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)} >
                        <option value="">Select Blood Group</option>
                        <option value="Don't know">Don't Know</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>

                      </select>
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <div className="col-md-12">
                      <label htmlFor="strAddress" className="text-black">Address <span className="text-danger">*</span></label>
                      <textarea type="text" className="form-control" id="strAddress" name="strAddress"
                        value={strAddress} onChange={(e) => setStrAddress(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <div className="col-md-4">
                      <label htmlFor="strCity" className="text-black">City <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="strCity" name="strCity" maxLength="30"
                        value={strCity} onChange={(e) => setStrCity(e.target.value)} required />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="strState" className="text-black">State <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="strState" name="strState" maxLength="30" value={strState}
                        onChange={(e) => setStrState(e.target.value)} required />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="strPincode" className="text-black">Pincode <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="strPincode"
                        name="strPincode"
                        value={strPincode}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6); // Allow only numbers and limit to 6 digits
                          setStrPincode(value);
                        }}
                        maxLength="6" required // Ensure maxlength is 6
                      />
                    </div>

                  </div>
                  <div className="form-group row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="strEmail" className="text-black">Email Address <span className="text-danger">*</span></label>
                      <input type="email" className="form-control" id="strEmail" name="strEmail"
                        value={strEmail} readOnly />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="strPhone" className="text-black">Phone Number <span className="text-danger">*</span></label>
                      <input type="tel" className="form-control" id="strPhone" name="strPhone"
                        value={strPhone} readOnly />
                    </div>
                  </div>
                  <h5 className='text-start mt-4' style={{ color: '#4E4E4E', borderBottom: '2px #BEBEBE solid', justifyContent: 'center' }}>Vehicle Information</h5>
                  <div className="form-group row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="vehiclePlateNumber" className="text-black">Vehicle Plate Number <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="vehiclePlateNumber"
                        name="vehiclePlateNumber" value={vehiclePlateNumber} onChange={(e) => setVehiclePlateNumber(e.target.value)} onBlur={() => validatePlateNumber(vehiclePlateNumber)}
                        maxLength="10" required />
                      {plateNumberError && <div className="text-danger">{plateNumberError}</div>}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="emergencyContactNumber" className="text-black">Emergency Contact Number <span className="text-danger">*</span></label>
                      <input
                        type="tel"
                        className="form-control"
                        id="emergencyContactNumber"
                        name="emergencyContactNumber"
                        value={emergencyContactNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Only allow numbers to be entered and ensure the max length is 10
                          if (/^\d{0,10}$/.test(value)) {
                            setEmergencyContactNumber(value);
                          }
                        }}
                        maxLength="10"
                        required
                        pattern="[0-9]{10}" // ensures the field will only be valid if exactly 10 digits are entered
                      />

                      {emergencyNumberError && <div className="text-danger">{emergencyNumberError}</div>}
                    </div>
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="form-group row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-block mt-3" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetail;
