import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

function Home() {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    // Fetch banner data from the API
    axios.post('https://apolloinfotech.in/QR_Genie/api/banner')
      .then(response => {
        const data = response.data; // Access the data from the response
        console.log(data); // Log the data to check if it contains the expected fields
        if (data.status === 'success' && data.Banner.length > 0) {
          const bannerData = data.Banner[0];
          // Adjust URL if it's a relative path
          const bannerUrl = bannerData.Banner_photo.startsWith('http') 
            ? bannerData.Banner_photo 
            : `https://apolloinfotech.in${bannerData.Banner_photo}`;
          setBanner({ ...bannerData, Banner_photo: bannerUrl });
        }
      })
      .catch(error => console.error('Error fetching banner:', error));
  }, []);

  return (
    <div>
      <div className="hero">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="intro-excerpt">
                <h1>Modern Interior <span className="d-block">Design Studio</span></h1>
                <p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
                <p>
                  <NavLink to="/userlogin" className="btn btn-white-outline me-2">Login</NavLink>
                  <NavLink to="/contactus" className="btn btn-white-outline">Contact</NavLink>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img-wrap mb-5">
                {banner ? (
                  <img src={banner.Banner_photo} className="img-fluid" alt="Qr-home" style={{height:'470px',width:'424px'}} />
                ) : (
                  <p>Please Wait...Loading banner...!</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-main mt-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-7">
              <h2 className="section-title mb-4 fw-bold">About us</h2>
              <p className="mb-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, dolorum, doloribus sunt dicta, officia voluptatibus libero necessitatibus natus impedit quam ullam assumenda? Id atque iste consectetur. Commodi odit ab saepe!
              </p>
              <p className="mb-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quos voluptatem suscipit neque enim, doloribus ipsum rem eos distinctio, dignissimos nisi saepe nulla? Libero numquam perferendis provident placeat molestiae quia?
              </p>
            </div>
            <div className="col-lg-5">
              <div className="img-wrap">
                <img src="/assests/image/About_img.png" alt="About us" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="product mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="bg-img">
                <img src="/assests/image/Rectangle 12.png" alt="Product Background" className="img-fluid" />
                <div className="mobile-img">
                  <img src="/assests/image/product_img.png" alt="Product" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product_contain">
                <h2 className="section-title mb-4 fw-bold">Our Products</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum sint exercitationem nobis animi velit similique error, eum reprehenderit quam ratione fugiat laborum id reiciendis laudantium. Ut error facere quo itaque?</p>
                <NavLink to="/productdetail" className="btn_main">
                  Buy Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
