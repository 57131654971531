import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

function QuickContact() {
  const [bloodGroup, setBloodGroup] = useState('');
  const [vehiclePlateNumber, setVehiclePlateNumber] = useState('');
  const [apiData, setApiData] = useState([]);

  // Placeholder image for vehicle plate number
  const quickContactImg = "assests/image/Quick_contact_img.png"; // Set the path to your vehicle plate image here

  useEffect(() => {
    // Retrieve data from localStorage
    const storedBloodGroup = localStorage.getItem('strBloodGroup');
    const storedVehiclePlateNumber = localStorage.getItem('vehiclePlateNumber');

    if (storedBloodGroup) setBloodGroup(storedBloodGroup);
    if (storedVehiclePlateNumber) setVehiclePlateNumber(storedVehiclePlateNumber);

    // Fetch API data here using POST request
    const fetchApiData = async () => {
      try {
        const response = await axios.post('https://apolloinfotech.in/QR_Genie/api/sms/template', {});
        if (response.data && response.data.ErrorCode === '0') {
          setApiData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchApiData();
  }, []);

  return (
    <div className="container mt-4">
      {/* Image for vehicle plate number */}
      <div>
        <img src={quickContactImg} alt="Vehicle Plate" style={{ width: '100%', height: 'auto' }} />
      </div>
      
      <div className="box-1 mb-4 p-3 border rounded shadow" style={{ borderRadius: '15px' }}>
        <h5 className="bg-dark text-white p-3 rounded mb-3">Contact Vehicle Plate Number</h5>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column me-2">
            <label htmlFor="vehicle-plate">Vehicle License Plate Number:  <span className="text-danger">*</span></label>
            <span style={{ fontSize: '1.5em', color: '#EF4F5F' }}>{vehiclePlateNumber || 'N/A'}</span>
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="blood-group">Blood Group: <span className="text-danger">*</span></label>
            {/* Flexbox container for image and blood group */}
            <div className="d-flex align-items-center">
              <img src="assests/image/Layer 2.svg" alt="Blood Picture" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              <span style={{ fontSize: '1.5em', color: '#EF4F5F' }}>{bloodGroup || 'N/A'}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="box-2 mb-4 p-3 border rounded shadow" style={{ borderRadius: '15px' }}>
        <h6 className="mb-3">Please select a message to contact owner of the vehicle</h6>
        <div className="d-flex flex-column">
          {apiData.map((dataItem, index) => (
            <div className="d-flex align-items-center mb-2" key={index}>
i 
              <div className="flex-grow-1">{dataItem.Template || `Dummy Sentence ${index + 1}`}</div>
              <input className="form-check-input custom-radio ms-2" type="radio" name="option" id={`option${index + 1}`} />
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button className="btn_emergency d-flex align-items-center me-2" style={{ width: '9em' }}>
            <i className="fas fa-envelope me-2"></i>
            Message
          </button>
          <button className="btn_emergency d-flex align-items-center" style={{ width: '9em', color: '#292929', border: '2px solid #292929' }}>
            <i className="fas fa-phone me-2"></i>
            Call
          </button>
        </div>
      </div>

      <div className="text-center mb-4">
        <div className="bg-dark text-white" style={{ borderRadius: '32px', maxWidth: '30em', margin: '0 auto', border: '2px solid #ddd', padding: '5px' }}>
          <div className="d-flex align-items-center">
            <div className="bg-white lock_icon" style={{ borderRadius: '50%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              <img src="/assests/image/call-lock-icon.svg" alt="privacy icon" style={{ width: '35px', height: '35px' }} />
            </div>
            <h5 className="ms-3 mb-0" style={{fontSize:'16px',textAlign:'center'}}>The privacy of all callers will be protected</h5>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <button className="btn_emergency">
          <img src="/assests/image/emergency_img.png" alt="privacy icon" className="me-2" style={{ width: '24px', height: '24px' }} />
          Emergency Contact Number
        </button>
      </div>

      <div className="text-center mb-4">
        <h5>
          Require QR Genie ? <NavLink to="/productdetail" style={{ color: '#EF4F5F', cursor: 'pointer', textDecoration: 'none' }}>Buy Now </NavLink>
        </h5>
      </div>
    </div>
  );
}

export default QuickContact;
