import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Navbar_white from './components/Navbar_white';
import Footer from './components/Footer';
import Home from './components/Home';
import Feature from './components/Feature';
import Contactus from './components/Contactus';
import Addtoproduct from './Innerpage/Addtoproduct';
import Checkout from './Innerpage/Checkout';
import Payment from './Innerpage/Payment';
import Qrcode_process from './Innerpage/Qrcode_process';
import PostpaidCheckout from './Innerpage/PostpaidCheckout';

import TermsCondition from './Footerpages/TermsCondition';
import RefundPolicy from './Footerpages/RefundPolicy';
import PrivacyPolicy from './Footerpages/PrivacyPolicy';
import ShippingDelivery from './Footerpages/ShippingDelivery';
import Login from './login_register/Login';
import Register from './login_register/Register';
import ProfileDetail from './login_register/ProfileDetail';
import LoginDetail from './login_register/LoginDetail';
import ProductDetail from './Innerpage/ProductDetail';
import Failed from './Innerpage/Failed';
// Update profile section
import UserLogin from './UserSection (Update)/UserLogin';
import UserProfile from './UserSection (Update)/UserProfile';
import MyQr from './UserSection (Update)/MyQr';
import EditNavbar from './UserSection (Update)/EditNavbar';
import UpdateLoginDetail from './UserSection (Update)/UpdateLoginDetail';
// import withAuth from './components/Authentication/Withauth';
// Reseller section
import ResellerLogin from './Reseller/ResellerLogin';
import MyOrder from './Reseller/MyOrder';
import MyQrcode from './Reseller/MyQrcode';
import NewOrder from './Reseller/NewOrder';
import MyAccount from './Reseller/MyAccount';
import ResellerNavbar from './Reseller/ResellerNavbar';
import PaymentList from './Reseller/PaymentList'
import Aboutus from './components/Aboutus';
import Quickbuy from './components/Quickbuy';
import MainContactus from './components/MainContactus';
import ScrollToTop from './components/ScrollToTop';
// Thank u
import ThankYouPage from './Thankyou page/ThankYouPage';
import ThankuCheckout from './Thankyou page/ThankuCheckout';
import ThankuPostpaid from './Thankyou page/ThankuPostpaid';
import ThankuReseller from './Thankyou page/ThankuReseller';

import QuickContact from './Innerpage/QuickContact';

function App() {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  return (
    <div className="App">
      <BrowserRouter>
        {/* Conditionally render the Navbar */}
        <NavbarWrapper />
        <ScrollToTop />
        <Routes>
      
          {/* main */}
          <Route exact path="/" element={<><Home /><Feature /><Contactus /></>} />
          <Route path="/navbarwhite" element={<Navbar_white />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/quickbuy" element={<Quickbuy />} />
          <Route path="/maincontactus" element={<MainContactus />} />


          <Route path="/addtoproduct" element={<Addtoproduct addToCart={addToCart} />} />
          <Route path="/checkout" element={<Checkout cart={cart} />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/qrcode_process/:dynamicContent" element={<Qrcode_process />} />
          <Route path="/postpaidcheckout" element={<PostpaidCheckout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profiledetail" element={<ProfileDetail />} />
          <Route path="/logindetail" element={<LoginDetail />} />
          <Route path="/quickcontact" element={<QuickContact />} />
          <Route path="/termscondition" element={<TermsCondition />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/shippingdelivery" element={<ShippingDelivery />} />
          <Route path="/productdetail" element={<ProductDetail />} />
          <Route path="/failed" element={<Failed />} />
          <Route path="/userlogin" element={<UserLogin />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/myqr" element={<MyQr />} />
          <Route path="/updatelogindetail" element={<UpdateLoginDetail />} />
          <Route path="/resellerlogin" element={<ResellerLogin />} />
          <Route path="/myorder" element={<MyOrder />} />
          <Route path="/myqrcode" element={<MyQrcode />} />
          <Route path="/neworder" element={<NewOrder />} />
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="/paymentlist" element={<PaymentList />} />
          <Route path="/resellernavbar" element={<ResellerNavbar />} />
          {/* thank u pages */}
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/thank-you-checkout" element={<ThankuCheckout />} />
          <Route path="/thank-you-postpaid" element={<ThankuPostpaid />} />
          <Route path="/thank-you-reseller" element={<ThankuReseller />} />
        </Routes>
        {/* Conditionally render the Footer */}
        <FooterWrapper />
      </BrowserRouter>
    </div>
  );
}

const NavbarWrapper = () => {
  const location = useLocation();

  if (location.pathname === '/quickcontact') {
    return null; // Do not render Navbar for QuickContact
  }

  if (location.pathname === '/') {
    return <Navbar />;
  }

  if (location.pathname.startsWith('/myorder') || location.pathname.startsWith('/myqrcode') || location.pathname.startsWith('/neworder') || location.pathname.startsWith('/myaccount')  || location.pathname.startsWith('/paymentlist')) {
    return <ResellerNavbar />;
  }

  if (location.pathname === '/myqr' || location.pathname === '/userprofile') {
    return <EditNavbar />;
  }

  return <Navbar_white />;
};

const FooterWrapper = () => {
  const location = useLocation();

  if (location.pathname === '/quickcontact') {
    return null; // Do not render Footer for QuickContact
  }

  return <Footer />;
};

export default App;
