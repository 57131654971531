import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <div>
      <nav className="custom-navbar navbar navbar-expand-md navbar-dark" aria-label="Furni navigation bar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src="/assests/image/QR Genie.png"  />
          </NavLink>
          <buttonx
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </buttonx>
          <div className="collapse navbar-collapse" id="navbarsFurni">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li><NavLink className="nav-link" to="/aboutus">About</NavLink></li>
              <li><NavLink className="nav-link" to="/productdetail">Product</NavLink></li>
              <li><NavLink className="nav-link" to="/productdetail">Quick Buy</NavLink></li>
              <li><NavLink className="nav-link" to="/contactus">Contact</NavLink></li>
            </ul>
            <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
              <li>
                <NavLink to="/userlogin">
                  <button className="Login_btn">Login</button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
