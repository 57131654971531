import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Checkout() {
  const navigate = useNavigate();
  const location = useLocation();

  const cartFromState = location.state?.cart || [];
  const totalPrice = location.state?.totalPrice || 0;

  const [cart, setCart] = useState(cartFromState);
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [promoApplied, setPromoApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    address: '',
    pincode: '',
    state: '',
    city: '',
    discount: 0,
    data: cart.map(item => ({
      productid: item.name.toLowerCase().includes('car') ? 1 : 2,
      quantity: item.quantity,
      price: item.price
    }))
  });
  const [errors, setErrors] = useState({});
  const [cartSubtotal, setCartSubtotal] = useState(cart.reduce((total, item) => {
    const price = parseFloat(item.price) || 0;
    const quantity = parseInt(item.quantity, 10) || 0;
    return total + price * quantity; 
  }, 0));
  const [totalAmount, setTotalAmount] = useState(cartSubtotal - discount);

  useEffect(() => {
    const updatedSubtotal = cart.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseInt(item.quantity, 10) || 0;
      return total + price * quantity; 
    }, 0);
    
    setCartSubtotal(updatedSubtotal);
    setTotalAmount(updatedSubtotal - discount);
    setFormData(prevFormData => ({
      ...prevFormData,
      data: cart.map(item => ({
        productid: item.name.toLowerCase().includes('car') ? 1 : 2,
        quantity: item.quantity,
        price: item.price
      }))
    }));
  }, [cart, discount]);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.email.trim()) formErrors.email = "Email is required";
    if (!formData.mobile.trim()) formErrors.mobile = "Mobile number is required";
    if (!formData.address.trim()) formErrors.address = "Address is required";
    if (!formData.city.trim()) formErrors.city = "City is required";
    if (!formData.pincode.trim() || formData.pincode.length !== 6) formErrors.pincode = "Pincode must be exactly 6 digits";
    if (!formData.state.trim()) formErrors.state = "State is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const applyPromoCode = async () => {
    setLoading(true);
  
    // Check if cart is empty or all items have a quantity of zero
    const isCartEmpty = cart.every(item => item.quantity <= 0);
    if (isCartEmpty) {
      toast.error('Please add at least one item to the cart before applying a promo code.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
      setLoading(false);
      return; // Prevent promo code application
    }
  
    try {
      const response = await axios.post(
        'https://apolloinfotech.in/QR_Genie/api/promocode',
        {
          totalAmount: cartSubtotal,
          coupon: promoCode
        }
      );
      const { Status, amount, Message } = response.data;
      if (Status === 'Success') {
        setDiscount(amount);
        setPromoApplied(true);
        setFormData(prevFormData => ({
          ...prevFormData,
          discount: amount
        }));
        toast.success(`Promo Code Applied: ${promoCode}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
        });
      } else {
        toast.error('Failed to apply promo code.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
        });
        setPromoApplied(false);
      }
    } catch (error) {
      console.error('Promo Code API Error:', error);
      toast.error('An error occurred while applying the promo code.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if cart is empty or all items have a quantity of zero
    const isCartEmpty = cart.every(item => item.quantity <= 0);
    if (isCartEmpty) {
      toast.error('Please add at least one item to the cart before placing an order.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
      return; // Prevent form submission
    }
  
    if (!validateForm()) {
      console.log("Validation failed");
      return;
    }
  
    setLoading(true); // Show loader when starting the checkout process
  
    try {
      const response = await axios.post(
        "https://apolloinfotech.in/QR_Genie/api/checkout",
        formData
      );
      const data = response.data;
  
      if (data.Status === "Success") {
        const paymentOptions = {
          key: 'rzp_test_2RwPgdzjFdXB0m',
          amount: Math.round(totalAmount * 100),
          currency: 'INR',
          name: 'QR Giene',
          description: 'Test Transaction',
          handler: async (paymentResponse) => {
            try {
              const paymentStatusResponse = await axios.post(
                'https://apolloinfotech.in/QR_Genie/api/paymentstatus',
                {
                  razorpay_id: paymentResponse.razorpay_payment_id,
                  order_id: data.data.orderID,
                  razorpay_order_id: 'order_OSqe0DVJGJCVzb',
                  razorpay_signature: '256bbfb75cd2f60932e47e828721b73078f73c387286fb5d810ddfab13bdfebf',
                  amount: Math.round(totalAmount * 100),
                  currency: "INR",
                  status: "completed"
                }
              );
              if (paymentStatusResponse.data.Status === "Success") {
                setFormData({
                  name: "",
                  mobile: "",
                  email: "",
                  address: "",
                  pincode: "",
                  state: "",
                  city: "",
                  data: []
                });
                navigate('/thank-you-checkout', { state: { orderID: data.data.orderID } });
              } else {
                navigate('/failed');
              }
            } catch (error) {
              console.error("Payment Status API Error:", error);
              navigate('/failed');
            } finally {
              setLoading(false); // Hide loader after payment status is processed
            }
          },
          prefill: {
            name: formData.name,
            email: formData.email,
            contact: formData.mobile
          },
          notes: {
            address: formData.address
          },
          theme: {
            color: "#F37254"
          },
          modal: {
            ondismiss: function () {
              navigate('/failed');
            }
          }
        };
  
        const rzp = new window.Razorpay(paymentOptions);
        rzp.open();
      } else {
        toast.error('Checkout failed.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Checkout API Error:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleQuantityChange = (index, increment) => {
    setCart(prevCart => {
      const updatedCart = [...prevCart];
      const item = updatedCart[index];
      const newQuantity = Math.max(0, item.quantity + increment);
      updatedCart[index] = { ...item, quantity: newQuantity };
      const updatedSubtotal = updatedCart.reduce((total, item) => {
        const price = parseFloat(item.price) || 0;
        const quantity = parseInt(item.quantity, 10) || 0;
        return total + price * quantity; 
      }, 0);
      setCartSubtotal(updatedSubtotal);
      setTotalAmount(updatedSubtotal - discount);
      return updatedCart;
    });
  };
  
  const renderIcon = (productName) => {
    if (productName.toLowerCase().includes('car')) {
      return <i className="fas fa-car" style={{ marginRight: '8px' }}></i>;
    }
    if (productName.toLowerCase().includes('bike')) {
      return <i className="fas fa-bicycle" style={{ marginRight: '8px' }}></i>;
    }
    return null;
  };

  return (
    <div className="checkout-container">
      {loading && (
        <div className="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      )}
      <ToastContainer />
      <div className="untree_co-section mt-5">
        <div className="breadcrumb__links" style={{ marginLeft: '5rem', marginBottom: '20px' }}>
          <NavLink to="/">Home</NavLink> 
          <span className="breadcrumb-separator"> &gt;&gt;&gt; </span>
          <NavLink to="/productdetail">Product</NavLink>
          <span className="breadcrumb-separator"> &gt;&gt;&gt; </span>
          <NavLink to="/checkout">Checkout</NavLink>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-5 mb-md-0">
              <h2 className="h3 mb-3 text-black">Shipping Details</h2>
              <div className="p3 p-lg-5 border bg-white">
                <form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <div className="form-group row">
                    <div className="col-md-12 mt-1">
                      <label htmlFor="name" className="text-black">Name <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder='Name' name="name" maxLength="50" value={formData.name} onChange={handleChange} required />
                      {errors.name && <small className="text-danger">{errors.name}</small>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label htmlFor="email" className="text-black">Email Address <span className="text-danger">*</span></label>
                      <input type="email" className="form-control" placeholder='Email' name="email" maxLength="50" value={formData.email} onChange={handleChange} required />
                      {errors.email && <small className="text-danger">{errors.email}</small>}
                    </div>
                    <div className="col-md-6 mt-1">
  <label htmlFor="mobile" className="text-black">Phone <span className="text-danger">*</span></label>
            <input type="text" className="form-control" name="mobile" placeholder='Phone'
              value={formData.mobile} onChange={handleChange} maxLength="10" pattern="\d{10}"     inputMode="numeric"  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} 
            required />
  {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
</div>

                  </div>
                  <div className="form-group row">
                    <div className="col-md-12 mt-1">
                      <label htmlFor="address" className="text-black">Address <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" name="address" placeholder='Address' maxLength="200" value={formData.address} onChange={handleChange} required />
                      {errors.address && <small className="text-danger">{errors.address}</small>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 mt-1">
                      <label htmlFor="city" className="text-black">City <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder='City' name="city" maxLength="30" value={formData.city} onChange={handleChange} required />
                      {errors.city && <small className="text-danger">{errors.city}</small>}
                    </div>
                    <div className="col-md-6 mt-1">
                      <label htmlFor="state" className="text-black">State <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder='State' name="state" maxLength="30" value={formData.state} onChange={handleChange} required />
                      {errors.state && <small className="text-danger">{errors.state}</small>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 mt-1">
                      <label htmlFor="pincode" className="text-black">Zipcode <span className="text-danger">*</span></label>
                      <input type="number" className="form-control" name="pincode" placeholder='Zipcode' value={formData.pincode} maxLength="6" pattern="\d{6}" onChange={(e) => {
                        if (e.target.value.length <= 6) {
                          handleChange(e); // Only allow up to 6 digits
                            }
                         }} required />
                      {errors.pincode && <small className="text-danger">{errors.pincode}</small>}
                    </div>
                  </div>
                  <button type="submit" className="btn btn-lg py-3 btn-block">Place Order</button>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              
              <h2 className="h3 mb-3 text-black">Order Details</h2>
              <div className='border'>
              <h5 style={{margin:'1rem 2rem'}}>Vehicle Stickers</h5>
              {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum perspiciatis id mollitia quia excepturi odit quam odio at enim libero est facere nulla quas eaque voluptatem cum, optio ipsum exercitationem!</p> */}
              <table className="table site-block-order-table mb-5 bg-white">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <h5 style={{ color: '#666666', display: 'flex', alignItems: 'center' }}>
                          {renderIcon(item.name)} {item.name}
                        </h5>
                      </td>
                      <td>
                        <div className="quantity">
                          <button className="value-button decrease-button" onClick={() => handleQuantityChange(index, -1)} 
                            disabled={item.quantity <= 0} title="Decrease">-</button>    
                          <span className="number" style={{ width: '100px', textAlign: 'center' }}>{item.quantity}</span>
                          <button className="value-button increase-button" onClick={() => handleQuantityChange(index, 1)} title="Increase">+</button>     
                        </div>
                      </td>
                      <td>₹{item.price * item.quantity}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" style={{ color: '#333333', fontWeight: '600' }}>Sub Total</td>
                    <td><strong>₹{cartSubtotal}</strong></td>     
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div className="d-flex justify-content-between align-items-center">
                        <input type="text" className="form-control" placeholder="Have a promo code?" 
                        value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        <button className="btn btn-block mb-3" onClick={applyPromoCode}>
                          {promoApplied ? 'Applied' : 'Apply'}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {promoApplied && (
                    <tr>
                      <td colSpan="2" >Promo Code Discount - <span style={{ color: 'white', backgroundColor: '#EF4F5F', fontWeight: '600',padding:'3px',borderRadius:'5px' }}>{promoCode}</span></td>
                      <td>₹{discount.toFixed(2)}</td>
                    </tr>
                  )}
                  {/* <tr>
                    <td colSpan="2" style={{ color: '#333333', fontWeight: '600' }}>Tax</td>
                    <td>₹0</td>
                  </tr> */}
                  <tr>
                    <td colSpan="2"><strong>Total</strong></td>
                    <td><strong>₹{totalAmount.toFixed(2)}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
