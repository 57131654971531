import React from 'react'
import { useLocation,NavLink } from 'react-router-dom';

function ThankuPostpaid() {
    const location = useLocation();
  const orderID = location.state?.orderID;
  const message = `Your Details Are Added Successfully..!`;
  return (
    <div>
    <h2 className="logo_heading">QR Genie</h2>
    <div className="text-center mt-5">
      <img src="assests/image/Thanku.png" alt="Company Picture" className="company-pic" />
      <p className='text-bold'>{message}</p>
      <NavLink to="/">
      <button className="Login_btn" style={{background:'#EF4F5F'}}>Home</button>
      </NavLink>
    </div>
  </div>
  )
}

export default ThankuPostpaid