import React from 'react'

function Feature() {
  return (
<div>
  {/* Feature Start */}
  <section>
    <div className="section-header mt-5">
      <h2>Features</h2>
      <p>Nulla dolorum nulla nesciunt rerum facere sed ut inventore quam porro nihil id ratione ea sunt quis dolorem dolore earum</p>
    </div>
    <div className="feature-section mt-5">
      <div className="container">
        <div className="row gx-0" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div className="col-lg-4">
            <div className="d-flex align-items-center h-100">
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square rounded-circle">
                  <i className="fa-regular fa-star" />
                </div>                    
                <div className="ps-3">
                  <h4>Features 1</h4>
                  <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui pariatur molestias rerum praesentium obcaecati quam reiciendis corrupti iure perspiciatis. Modi fugit voluptate itaque! Porro, unde temporibus facilis officiis debitis non.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center h-100">
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square rounded-circle">
                  <i className="fa-regular fa-star" />
                </div>
                <div className="ps-3">
                  <h4>Features 2</h4>
                  <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis quidem, nam soluta fugiat alias voluptatibus dolorem esse, in, temporibus cumque rerum architecto itaque totam molestiae cum accusamus laboriosam. Iusto, sapiente.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center h-100">
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square rounded-circle">
                  <i className="fa-regular fa-star" />
                </div>
                <div className="ps-3">
                  <h4>Features 3</h4>
                  <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque culpa laudantium, tempora reiciendis at voluptate fugiat, doloribus exercitationem corporis quia non nesciunt a rerum ad nobis animi? Assumenda, dolorem adipisci! </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Feature End */}
</div>

  )
}

export default Feature