import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function EditNavbar() {
  const location = useLocation();
  const { UserLoginID } = location.state || {};

  return (
    <div>
      <nav className="custom-navbar navbar navbar-expand-md navbar-dark bg-dark" aria-label="Furni navigation bar">
        <div className="container">
          <NavLink className="navbar-brand" to={{ pathname: "/", state: { UserLoginID } }}>
            <img src="/assests/image/QR Genie.png" alt="QR Genie" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarsFurni">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li>
                <NavLink className="nav-link" to={{ pathname: "/myqr", state: { UserLoginID } }}>My QR</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to={{ pathname: "/userprofile", state: { UserLoginID } }}>My Profile</NavLink>
              </li>
            </ul>
            <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
              <li className="nav-item" style={{ marginRight: 50 }}>
                <NavLink className="nav-link active" to="/userlogin">
                  <i className="fas fa-sign-out-alt" /> Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default EditNavbar;
