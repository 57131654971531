import React from 'react';

function Aboutus() {
  return (
    <div className="text-center mb-3 mt-3">
    <img src="assests/image/construction.jpg" alt="Company Picture" className="company-pic" style={{height:'250px'}} />
  </div>
  );
}

export default Aboutus;
