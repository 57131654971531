import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

function UserLogin() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // For OTP
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(300); // Timer for 5-minute countdown
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      console.log('localStorage is accessible.');
    } catch (e) {
      console.error('localStorage is not accessible:', e);
      setError('localStorage is not available, please check your browser settings.');
    }

    const storedUserLoginID = localStorage.getItem('UserLoginID');
    const storedVehicleOwnerMasterDetailId = localStorage.getItem('VehicleOwnerMasterDetailId');
    console.log('UserLoginID on page load:', storedUserLoginID);
    console.log('VehicleOwnerMasterDetailId on page load:', storedVehicleOwnerMasterDetailId);
  }, []);

  useEffect(() => {
    let timer;
    if (isOtpSent && countdown > 0) {
      timer = setInterval(() => setCountdown(prev => prev - 1), 1000);
    } else if (countdown <= 0) {
      setError('OTP expired. Please request a new OTP.');
      setIsOtpSent(false); // Reset to the email and phone input form
      setPassword(''); // Clear the OTP input
      setCountdown(300); // Reset countdown
    }
    return () => clearInterval(timer);
  }, [countdown, isOtpSent]);

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    if (/^\d{0,10}$/.test(input)) {
      setPhone(input);
      setError(''); // Clear error when user starts typing
    } else {
      setError('Please enter a valid 10-digit phone number.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Clear error when user starts typing
  };

  const handleOtpChange = (e) => {
    setPassword(e.target.value);
    setError(''); // Clear error when user starts typing
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (phone.length !== 10) {
      setError('Please enter a valid 10-digit phone number.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    const loginUrl = 'https://apolloinfotech.in/QR_Genie/api/vehicle_owner/login';

    try {
      setIsLoading(true); // Start loading
      const response = await axios.post(loginUrl, { email, phone });
      if (response.data.Status === 'Success') {
        setIsOtpSent(true);
        setCountdown(300); // Start 5-minute countdown
        setError('');
        toast.success(response.data.Message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide, // Use Slide transition
        });
      } else {
        setError(response.data.Message || 'Failed to send OTP')
        toast.error('Failed to send OTP', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
      });
      }
    } catch (error) {
      setError('An error occurred during login.')
      toast.error('An error occurred during login.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
    });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const verifyOtpUrl = 'https://apolloinfotech.in/QR_Genie/api/vehicle_owner/verifyOTP';

    try {
      setIsLoading(true); // Start loading
      const response = await axios.post(verifyOtpUrl, { strEmail: email, mobile: phone, password });
      if (response.data.ErrorCode === '0') {
        const { UserLoginID, VehicleOwnerMasterDetailId } = response.data;

        try {
          localStorage.setItem('UserLoginID', UserLoginID);
          localStorage.setItem('VehicleOwnerMasterDetailId', VehicleOwnerMasterDetailId);
        } catch (e) {
          console.error('Failed to save to localStorage:', e);
          setError('Failed to save session data. Please check your browser settings.');
          return;
        }

        console.log('Stored UserLoginID:', localStorage.getItem('UserLoginID'));
        console.log('Stored VehicleOwnerMasterDetailId:', localStorage.getItem('VehicleOwnerMasterDetailId'));

        navigate('/myqr', {
          state: { UserLoginID, VehicleOwnerMasterDetailId, email, phone },
        });
      } else {
        setError(response.data.Message || 'Failed to verify OTP');
      }
    } catch (error) {
      setError('Invalid OTP..!');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Convert countdown seconds to MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="container mt-5 mb-5">
      <ToastContainer />
      <div className="text-center mt-5">
        <h2 className="logo_heading">QR Genie</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="text-center mb-3">
            <img src="assests/image/login_img.png" alt="Company Picture" className="company-pic" />
          </div>
        </div>
        <div className="col-md-6">
          <form className="verification-form mt-5" onSubmit={isOtpSent ? handleVerifyOtp : handleLogin}>
            <h2 className="login_heading">{isOtpSent ? 'Verify OTP' : 'User Login'}</h2>

            {!isOtpSent && (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="phone">Phone Number <span className="required-asterisk">*</span></label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">+91</span>
                    </div>
          <input type="tel" className="form-control" id="phone" value={phone}
          onChange={handlePhoneChange} placeholder="Enter phone number" required
            inputMode="numeric" pattern="[0-9]{10}" />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="email">Email Address <span className="required-asterisk">*</span></label>
          <input type="email" className="form-control" id="email" value={email}
          onChange={handleEmailChange} placeholder="Enter email address" maxLength="50" required />
                </div>
              </>
            )}

            {isOtpSent && (
              <div className="form-group mb-3">
              <label htmlFor="password">OTP <span className="required-asterisk">*</span></label>
              <input type="text" className="form-control" id="password" value={password}
                onChange={handleOtpChange} placeholder="Enter OTP" maxLength={6} required
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                }} />
              <small className="text-muted">OTP expires in {formatTime(countdown)}</small>
            </div>
            
            )}

            {error && <div className="alert alert-danger">{error}</div>}

            <button type="submit" className="btn btn-block mt-3" disabled={isLoading}>
              {isLoading ? (
                <span>
                  <span className="loading-dots">
                    <span>.</span><span>.</span><span>.</span>
                  </span> 
                  {isOtpSent ? 'Verifying OTP' : 'Sending OTP'}
                </span>
              ) : (
                isOtpSent ? 'Verify OTP' : 'Send OTP'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
