import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function Qrcode_process() {
  const { dynamicContent } = useParams();
  const navigate = useNavigate();
  const [qrData, setQrData] = useState(null);
  const [error, setError] = useState(null);

  const fetchQrData = async (guid) => {
    const MasterURL = 'https://apolloinfotech.in/';
    const strBaseURL = `${MasterURL}QR_Genie/api/qrcodedetail`;

    try {
      setError(null); // Clear previous error
      const response = await axios.post(strBaseURL, { guid });
      console.log('API response:', response.data); 
      const data = response.data;

      if (data.ErrorCode === "0" && data.Status === "Success") {
        setQrData(data.data);
        
        // Store emergencyContactNumber in local storage
        if (data.data.emergencyContactNumber) {
          localStorage.setItem('emergencyContactNumber', data.data.emergencyContactNumber);
        }
        if (data.data.strBloodGroup) {
          localStorage.setItem('strBloodGroup', data.data.strBloodGroup);
        }
        if (data.data.vehiclePlateNumber) {
          localStorage.setItem('vehiclePlateNumber', data.data.vehiclePlateNumber);
        }

        const cartItems = [
          {
            id: data.data.id,
            name: data.data.QRCodeFor,
            price: data.data.rate, 
            rate: data.data.rate,
            productid: data.data.productid,  // Add productid from API response
           productname: data.data.productname  // Add productname from API response
          },
        ];

        if (data.data.QRCodeFor === 1 || data.data.QRCodeFor === 2) { // 1,2: prepaid, online
          if (data.data.VehicleOwnerMasterId > 0 && data.data.VehicleOwnerMasterDetailId > 0) {
            navigate('/quickcontact', { state: { guid, VehicleOwnerMasterId: data.data.VehicleOwnerMasterId } });
          } else {
            navigate('/login', { state: { guid, VehicleOwnerMasterId: data.data.VehicleOwnerMasterId } });
          }
        } else if (data.data.QRCodeFor === 3) { // postpaid
          if (data.data.isPaid === 1) {
            if (data.data.VehicleOwnerMasterId > 0 && data.data.VehicleOwnerMasterDetailId > 0) {
              navigate('/quickcontact', { state: { guid, VehicleOwnerMasterMasterId: data.data.VehicleOwnerMasterId } });
            } else {
              navigate('/login', { state: { guid, VehicleOwnerMasterId: data.data.VehicleOwnerMasterId } });
            }
          } else {
            navigate('/postpaidcheckout', { state: { cart: cartItems, rate: data.data.rate, guid, VehicleOwnerMasterId: data.data.VehicleOwnerMasterId } });
          }
        } else {
          setError("Please contact admin or support.");
        }
      } else {
        setError(`Failed to fetch QR code data: ${data.Message || 'Unknown error'}`);
      }
    } catch (error) {
      setError("An error occurred while fetching the data.");
      console.error('Error fetching the data:', error);
    }
  };

  useEffect(() => {
    if (dynamicContent) {
      fetchQrData(dynamicContent);
      console.log(dynamicContent);
    } else {
      setError("GUID is not provided.");
    }
  }, [dynamicContent]);

  return (
    <div className='text-center'>
      <h1>Welcome! {dynamicContent}</h1>
      {error && <p>{error}</p>}
      {qrData ? (
        <div>
          <p>QR Code ID: {qrData.id}</p>
          <p>QR Code Text: {qrData.QRCodeText}</p>
          <p>QR Code For: {qrData.QRCodeFor}</p>
          <p>Is Paid: {qrData.isPaid ? 'Yes' : 'No'}</p>
          <p>Activation Date: {qrData.ActivationDate || 'N/A'}</p>
          <p>Expiry Date: {qrData.ExpiryDate || 'N/A'}</p>
          <p>Rate: {qrData.rate}</p>
        </div>
      ) : (
        !error && <p>Loading...</p>
      )}
    </div>
  );
}

export default Qrcode_process;
